<template lang="pug">
page-structure-element(
  :element="element"
  :defaultImage="defaultImage"
  :interactions="interactions"
  @clicked="navigateTo('CloseButtonPane')"
) {{ selectedPage.isTeaser && isPermanentTeaser ? $t('teaserCloseButton') : $t('closeButton') }}
</template>

<script>
  import { mapState } from 'vuex';
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureElement from '../PageStructureElement.vue';

  export default {
    components: { PageStructureElement },
    mixins: [pageStructureMixin],
    props: {
      element: {
        type: Object,
        required: true,
      },
      interactions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        defaultImage: require('@/assets/editor/svg/page-structure-elements/CloseButton.svg'),
      };
    },
    computed: {
      ...mapState(['data', 'selectedPage']),
      isPermanentTeaser() {
        return this.data.isPermanentTeaser;
      },
    },
  };
</script>
